import React, { useState } from 'react'
import { StaticImage } from "gatsby-plugin-image"
import "../../static/styles/main.scss"

const RegisterNewsLetterForm = ({ backendUrl, fromWhere }) => {    
    const [name, setName] = useState("なし")
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [sendDone, setSendDone] = useState(false)
    const [sendingText, setSendingText] = useState("処理を実行しています...")

    const callFetch = () => {                                                                           //http://localhost:5001/nov182021/us-central1/server/mail/download-materials
        fetch(backendUrl, {   // https://us-central1-nov182021.cloudfunctions.net/server/mail/download-materials
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: name, email: email, fromWhere: fromWhere })
        })
        .then(res => res.json())
        .then(data => {
            console.log("res.send:data::::", data)
            setSendDone(true)
        })
        .catch(err => alert("エラーが発生しました。ページをリロードして、もう一度トライしてください。", err)) 
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        callFetch()
        setTimeout(() => {
            setSendingText("メール送信の準備をしています...")
        }, 3100) 
        setTimeout(() => {
            setSendingText("処理を実行中です...") 
        }, 6000) 
    }
  
    const handleNameChange = (e) => {
        setName(e.currentTarget.value)
    }

    const handleEmailChange = (e) => {
        setEmail(e.currentTarget.value)
    }
    return (
        <>
            {
            sendDone ? 
                <h4>ありがとうございます。メールを送信したので受信ボックスをご確認ください。</h4>
            :
            <form onSubmit={handleSubmit}>
                <div>
                    {/* <input onChange={handleNameChange} value={name} placeholder="*お名前" name="name" type="text" required/> */}
                    <input onChange={handleEmailChange} value={email} placeholder="*受信するメールアドレスを入力してください" name="email" type="text" required/>
                    <button type="submit" disabled={loading}>
                        {loading ? 
                            <>{sendingText}</>
                            :
                            <><span>送信</span></>
                        }
                    </button>
                </div>
            </form>
            }
        </>
    )
}

const RegisterNewsLetterBody = ({ backendUrl, text, fromWhere }) => {
    return(
        <div className="new-letter">
            <div className="border-line">
                <div className="img-text-container">
                    <div className="img-container">
                        <StaticImage src="../../../static/images/picMyself/writer-pic-200x200.png" alt="Profile Pic" className="profile-img" placeholder="blurred" />
                    </div>
                    <div className="text-container">
                        <p>メルマガ配信中<br/>（from 三好アキ／エンジニア）</p>
                        <br/>
                        {text}
                        <br/>
                    </div>
                </div>
                <RegisterNewsLetterForm backendUrl={backendUrl} fromWhere={fromWhere}/>
            </div>
        </div>
    )
}

export default RegisterNewsLetterBody